import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take, takeUntil } from 'rxjs';
import {
  GPTChatRolesEnum,
  IChatMessage,
  IChatRead,
  IChatReadMessages,
  IChatUpdate,
  IChatUpdateWithNewMessage,
} from '../models';
import { BackendService } from './backend.service';

@Injectable()
export class ChatService {
  activeChat$ = new BehaviorSubject<IChatRead | null>(null);
  sidebarActiveState$ = new BehaviorSubject<boolean>(false);
  tempChat: IChatUpdate | null = null;
  public chatsHistoryList$ = new BehaviorSubject<IChatRead[]>([]);

  constructor(private readonly backendService: BackendService) {
    this.loadChatsHistoryList();
  }

  public toggleSidebar(): void {
    this.sidebarActiveState$.next(!this.sidebarActiveState$.value);
  }

  // activeChat$

  public updateActiveAndTempChat(chat: IChatUpdate): void {
    const activeChat = this.activeChat$.value;
    if (activeChat) {
      Object.assign(activeChat, chat);
      this.activeChat$.next(activeChat);
      this.tempChat = null;
      return;
    }
    if (!this.tempChat) {
      this.tempChat = chat;
    } else {
      Object.assign(this.tempChat, chat);
    }
  }

  private updateActiveChatTotalTokens(count: number): void {
    if (!this.activeChat$.value) {
      return;
    }
    const chat = this.activeChat$.value;
    Object.assign(chat, { total_tokens: count });
    this.activeChat$.next(chat);
  }

  // chats history list

  private loadChatsHistoryList(): void {
    this.backendService
      .getChatsHistoryList()
      .pipe(take(1))
      .subscribe((res) => {
        this.chatsHistoryList$.next(res);
      });
  }

  private pushNewChatToChatsHistoryList(chat: IChatRead): void {
    const chats = this.chatsHistoryList$.value;
    chats.push(chat);
    this.chatsHistoryList$.next(chats);
  }

  private removeChatFromChatsHistoryListById(chat_id: number): void {
    const chatsWithoutRemoved = this.chatsHistoryList$.value.filter((chat) => chat.id !== chat_id);
    this.chatsHistoryList$.next(chatsWithoutRemoved);
  }

  // get first new chat (total_tokens=0) or undefined if there isn't new chat
  private getNewChatFromHistoryList(): IChatRead | undefined {
    return this.chatsHistoryList$.value.find((chat) => chat.total_tokens === 0);
  }

  // message

  private prepareChatWithNewMessageData(chat: IChatUpdate, messageContent: string): IChatUpdateWithNewMessage {
    return {
      chat_ser: {
        model: chat.model,
        max_tokens: chat.max_tokens,
        temperature: chat.temperature,
      },
      message_ser: {
        role: GPTChatRolesEnum.user,
        content: messageContent,
      },
    };
  }

  public async sendMessage(messageContent: string, stopResponseTrigger$: Observable<any>): Promise<IChatMessage> {
    return new Promise((resolve, reject) => {
      const activeChat = this.activeChat$.value;
      if (activeChat) {
        const chatWithNewMessage = this.prepareChatWithNewMessageData(activeChat, messageContent);
        this.backendService
          .postMessage(activeChat.id, chatWithNewMessage)
          .pipe(take(1), takeUntil(stopResponseTrigger$))
          .subscribe({
            next: (res) => {
              this.updateActiveChatTotalTokens(res.total_tokens);
              resolve(res.messages_all[res.messages_all.length - 1]);
            },
            error: () => {
              reject(false);
            },
            complete: () => {
              reject(false);
            },
          });
      } else reject(false);
    });
  }

  // chat

  public createNewChat(): void {
    const newChat = this.getNewChatFromHistoryList();
    if (newChat) {
      this.activeChat$.next(newChat);
      return;
    }

    this.backendService
      .postChat(this.tempChat || {})
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          const chat = { ...res, is_new: true };
          this.pushNewChatToChatsHistoryList(chat);
          this.activeChat$.next(chat);
        },
      });
  }

  public getChat(chatId: number): Observable<IChatReadMessages> {
    return this.backendService.getChat(chatId);
  }

  public updateChat(chatId: number, data: Partial<IChatUpdate>): Observable<IChatReadMessages> {
    return this.backendService.putChat(chatId, data);
  }

  public removeChat(chatId: number): void {
    this.backendService
      .deleteChat(chatId)
      .pipe(take(1))
      .subscribe(() => {
        this.removeChatFromChatsHistoryListById(chatId);
        if (this.activeChat$.value?.id === chatId) {
          this.activeChat$.next(null);
        }
      });
  }
}
