import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IUserCreate, IUserRead, SelectOption, UserRolesEnum } from '../models';
import { BackendService } from './backend.service';
import { UserRolesOptionsList } from '../constants';

@Injectable()
export class UserService {
  public currentUser$: BehaviorSubject<IUserRead | null> = new BehaviorSubject<IUserRead | null>(null);
  public currentUserIsHead = false;
  public readonly userRolesOptionsList: SelectOption<string>[] = UserRolesOptionsList;

  constructor(
    private readonly backendService: BackendService,
    private readonly toastrService: NbToastrService,
  ) {}

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.getUsersMe()
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.loadCurrentUserProfile(res);
            resolve(true);
          },
          error: () => {
            reject(false);
          },
        });
    });
  }

  logOut() {
    this.currentUser$.next(null);
  }

  updateMyProfile(data: Partial<IUserRead>, toastrSuccess?: string): void {
    this.updateUserMe(data)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.currentUser$.next(res);
          this.currentUserIsHead = res.is_head;
          this.toastrService.success(toastrSuccess || 'Successfully updated', 'Success');
        },
      });
  }

  public loadCurrentUserProfile(user?: IUserRead): void {
    if (user) {
      this.currentUser$.next(user);
      this.currentUserIsHead = user.is_head;
    } else {
      this.getUsersMe()
        .pipe(take(1))
        .subscribe((userProfile) => {
          this.currentUser$.next(userProfile);
          this.currentUserIsHead = userProfile.is_head;
        });
    }
  }

  /** Get user role from user object*/
  public getRoleFromUserProfile(user: IUserRead): UserRolesEnum {
    if (user?.is_head) {
      return UserRolesEnum.HEAD;
    }
    return UserRolesEnum.USER;
  }

  public updateUserPrompts(prompt: string): Promise<true> {
    return new Promise((resolve, reject) => {
      this.updateUserMe({ prompt })
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.currentUser$.next(res);
            this.toastrService.success('Successfully saved', 'Success');
            resolve(true);
          },
          error: reject,
        });
    });
  }

  /**--------------------------- Backend requests ----------------*/

  userUpdateRequest(payload: Partial<IUserRead>, userId: number): Observable<IUserRead> {
    return this.backendService.put(`/api/v1/users/${userId}`, payload);
  }

  updateUserMe(payload: Partial<IUserRead>): Observable<IUserRead> {
    return this.backendService.put('/api/v1/users/me', payload);
  }

  getUsers(): Observable<IUserRead[]> {
    return this.backendService.get('/api/v1/users/');
  }

  userCreate(payload: IUserCreate): Observable<IUserRead> {
    return this.backendService.post('/api/v1/users/', payload);
  }

  deleteUser(id: number): Observable<any> {
    return this.backendService.delete(`/api/v1/users/remove/${id}`);
  }

  resendEmailVerify(userId: number): Observable<IUserRead> {
    return this.backendService.put(`/api/v1/users/send-request-verify-email-and-reset-password/${userId}`, {});
  }

  private getUsersMe(): Observable<IUserRead> {
    return this.backendService.get(`/api/v1/users/me`);
  }
}
