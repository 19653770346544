import { Component } from '@angular/core';

@Component({
  selector: 'app-error-page',
  template: ` <div class="error-page-container">
    <h1 class="mb-4 error-heading">Error</h1>
    <p>There were problems with loading data.</p>
    <p>Check your internet connection or contact your administrator.</p>
  </div>`,
  styles: [
    `
      .error-page-container {
        text-align: center;
        padding-left: 2rem;
        position: absolute;
        z-index: 10000;
        background-color: white;
        width: 100vw;
        height: 100vh;
      }

      .error-heading {
        font-size: 15rem;
        color: #808080;
      }

      p {
        font-size: 4rem;
      }
    `,
  ],
})
export class ErrorPageComponent {}
