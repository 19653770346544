import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class RoleGuard {
  constructor(private auth: KeycloakService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    const expectedRoles = next.data['expectedRoles'];
    if (!this.auth) {
      return this.router.parseUrl('/chats');
    }
    if (expectedRoles.filter((role: string) => this.auth?.isUserInRole(role))?.length) {
      return true;
    } else {
      return this.router.parseUrl('/chats');
    }
  }
}
