import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './@core/guards/auth-guard';
import { RoleGuard } from './@core/guards/role-guard';
import { AppComponent } from './app.component';
import { ErrorPageComponent } from './service-pages/error-page/error-page.component';
import { LogoutComponent } from './service-pages/logout/logout.component';
import { KeycloakUserRolesEnum } from './@core/enums';

export const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [KeycloakUserRolesEnum.HEAD],
        },
      },
      {
        path: 'chats',
        loadChildren: () => import('./modules/chats/chats.module').then((m) => m.ChatsModule),
        canActivate: [AuthGuard],
      },
      {
        path: '404',
        component: ErrorPageComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      { path: '**', redirectTo: 'chats' },
    ],
  },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
