import { Component } from '@angular/core';
import { UserService } from './@core/services';

@Component({
  selector: 'app-app',
  template: ` <nb-layout *ngIf="allIsLoad">
    <nb-layout-column class="p-0">
      <router-outlet></router-outlet>
    </nb-layout-column>
  </nb-layout>`,
})
export class AppComponent {
  allIsLoad = false;

  constructor(private userService: UserService) {
    this.userService
      .init()
      .then(() => {
        this.allIsLoad = true;
      })
      .catch(() => {});
  }
}
