import { SelectOption, UserRolesEnum } from './models';
import { ChatModelEnum } from './enums';

export const ChatModelOptions: SelectOption<ChatModelEnum>[] = [
  {
    title: 'gpt-3.5',
    value: ChatModelEnum.gpt35,
  },
  {
    title: 'gpt-4',
    value: ChatModelEnum.gpt4,
  },
  {
    title: 'gpt-4-vision',
    value: ChatModelEnum.gpt4vision,
  },
];

export const ChatTemperatureOptionsList: number[] = [];
for (let i = 0.0; i < 2.1; i += 0.1) {
  ChatTemperatureOptionsList.push(parseFloat(i.toFixed(1)));
}

export const UserRolesOptionsList: SelectOption<string>[] = [];
Object.values(UserRolesEnum).map((value: string) => {
  UserRolesOptionsList.push({
    title: value,
    value,
  });
});
