import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { SelectOption } from '../models';

@Injectable()
export class UtilsService {
  /* eslint-disable no-useless-escape */
  private readonly pattern =
    /(^$|(^([^<>()\[\]\\,;:\s@"]+(\.[^<>()\[\]\\,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
  /* eslint-disable no-useless-escape */
  private readonly forbiddenSymbolsEmailRegex = /[~`{}/|?!№#$%^&*":;,[\]<>()=']/gi;
  /* eslint-disable no-useless-escape */
  private readonly checkCyrilic = /[а-яА-ЯёЁ]/gi;
  /* eslint-disable no-useless-escape */
  private readonly linkReg = new RegExp(/(https:|http:)\/\/(?:www\.|(?!www))([\da-zA-Z-_.\/]+(\.[a-zA-Z]+)+)/gi);

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pattern = new RegExp(this.pattern).test(control.value);
      const forbidden = new RegExp(this.forbiddenSymbolsEmailRegex).test(control.value);
      const excludeCyrilic = new RegExp(this.checkCyrilic).test(control.value);
      return pattern && !forbidden && !excludeCyrilic ? null : { emailInvalid: true };
    };
  }

  urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pattern = new RegExp(this.linkReg).test(control.value);
      return pattern ? null : { invalidUrl: true };
    };
  }

  missingInTheListValidator(list: SelectOption[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!list.length || !control.value) {
        return null;
      } else {
        return list.find((option) => option.title === control.value) ? null : { notExist: true };
      }
    };
  }

  duplicateValidator(list: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!list.length || !control.value) {
        return null;
      } else {
        return !list.find((item) => item === control.value) ? null : { duplicate: true };
      }
    };
  }

  getDateISOStringInZeroTimezone(date: any): string {
    if (!date) return '';
    if (!(date instanceof Date)) {
      return new Date(date).toISOString();
    }
    const timezoneOffset: number = new Date().getTimezoneOffset();
    const timestamp = Date.parse(date.toString());
    let timestampWithCorrectOffset = 0;
    if (timezoneOffset === 0) {
      timestampWithCorrectOffset = timestamp;
    } else {
      timestampWithCorrectOffset = timestamp + timezoneOffset * 60000;
    }
    return new Date(timestampWithCorrectOffset).toISOString();
  }

  getDateInZeroTimezone(incomeDate: string | null | Date): Date | string {
    if (!incomeDate) return '';
    const timezoneOffset: number = new Date().getTimezoneOffset();
    let timestamp: number;
    if (incomeDate instanceof Date) {
      timestamp = new Date(incomeDate.toISOString().split('T')[0] + 'T12:00:00.000Z').getTime();
    } else {
      timestamp = new Date(incomeDate.split('T')[0] + 'T12:00:00.000Z').getTime();
    }
    let timestampWithCorrectOffset = 0;
    if (timezoneOffset === 0) {
      timestampWithCorrectOffset = timestamp;
    } else {
      timestampWithCorrectOffset = timestamp + timezoneOffset * 60000;
    }
    return new Date(timestampWithCorrectOffset);
  }

  public urlifyString(value: string): string {
    const regexpWithCheck = /[^>"](https:|http:)\/\/(?:www\.|(?!www))([\da-zA-Z-_.\/]+(\.[a-zA-Z]+)+)/gi;
    return value.replace(regexpWithCheck, (url) => {
      const link = url.substring(1);
      return `${url.substring(0, 1)} <a href="${link}" contentEditable="false" target="_blank">${link}</a>`;
    });
  }

  getParsedData<T>(string: unknown): T {
    let parsedData;
    const value: null | string = null;
    if (string && typeof string === 'string' && string !== '') {
      const temp: string = string.replace(/'/g, '"').replace(/False/g, 'false');
      const parsed: string | unknown[] = this.getParsedFromJson(temp);
      if (parsed && Array.isArray(parsed) && parsed.length) {
        parsedData = parsed.map((item: string) => this.getParsedFromJson(item));
      } else {
        parsedData = parsed;
      }
    } else {
      parsedData = value;
    }
    return parsedData as T;
  }

  public cloneArrayOfObjects<T>(array: object[]): T[] {
    return array.map((obj) => Object.assign({}, obj)) as T[];
  }

  public getFilteredBySerchArray<T = any>(arr: { [key: string]: any }[], query: string): T[] {
    return arr.filter((item) => this.isQueryIncludesInObject(item, query)) as T[];
  }

  private getParsedFromJson(str: string): string {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  private isQueryIncludesInObject(obj: { [key: string]: any }, query: string): boolean {
    return Object.keys(obj).some((key) => {
      if (!obj[key]) {
        return false;
      }
      if (typeof obj[key] === 'object') {
        return this.isQueryIncludesInObject(obj[key], query);
      }
      if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
        return obj[key].toString().toLowerCase().includes(query.toLowerCase());
      }
      return false;
    });
  }
}
