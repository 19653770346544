import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IChatRead, IChatReadMessages, IChatUpdate, IChatUpdateWithNewMessage } from '../models';

@Injectable()
export class BackendService {
  private readonly baseUrl: string = environment.API;
  private readonly chatsV1Url: string = '/api/v1/chats';

  constructor(
    private readonly _http: HttpClient,
    private toastrService: NbToastrService,
  ) {}

  public get<T>(_url: string, options = {}): Observable<T> {
    return this._http.get<T>(this.baseUrl + _url, options).pipe(catchError((err) => this.errorHandler(err)));
  }

  public post<T>(_url: string, data: any, options = {}): Observable<T> {
    return this._http.post<T>(this.baseUrl + _url, data, options).pipe(catchError((err) => this.errorHandler(err)));
  }

  public put<T>(_url: string, data: any, options = {}): Observable<T> {
    return this._http.put<T>(this.baseUrl + _url, data, options).pipe(catchError((err) => this.errorHandler(err)));
  }

  public delete<T>(_url: string, options = {}): Observable<T> {
    return this._http.delete<T>(this.baseUrl + _url, options).pipe(catchError((err) => this.errorHandler(err)));
  }

  getErrorFromDetail(detail: { loc: string[]; msg: string }[]): string {
    let errorString = '';
    detail.forEach((error) => {
      errorString += `Field: ${error?.loc[1]} Error: ${error?.msg}\n`;
    });
    return errorString;
  }

  getErrorMessage(error: HttpErrorResponse | string): string {
    if (typeof error === 'string') {
      return error;
    } else if (typeof error?.error?.detail === 'string') {
      return error?.error?.detail;
    } else if (typeof error?.error?.detail?.error?.message === 'string') {
      return error.error.detail.error.message;
    } else if (error?.error?.detail?.length > 0) {
      return this.getErrorFromDetail(error.error.detail);
    } else if (typeof error.error?.message === 'string') {
      return error.error.message;
    } else if (typeof error?.statusText === 'string') {
      return error?.statusText;
    } else {
      return 'Something went wrong';
    }
  }

  private errorHandler(error: HttpErrorResponse | string) {
    console.error(error);
    const errMessage = this.getErrorMessage(error);

    this.toastrService.danger(errMessage, 'Unsuccessful', {
      duration: 6000,
      destroyByClick: true,
    });
    return throwError(() => error);
  }

  // chats requests

  public deleteChat(chatId: number): Observable<Response> {
    return this.delete<Response>(`${this.chatsV1Url}/${chatId}`);
  }

  postChat(chat: IChatUpdate): Observable<IChatReadMessages> {
    return this.post<IChatReadMessages>(`${this.chatsV1Url}/`, chat);
  }

  getChat(chatId: number): Observable<IChatReadMessages> {
    return this.get(`${this.chatsV1Url}/${chatId}`);
  }

  putChat(chatId: number, data: Partial<IChatUpdate>): Observable<IChatReadMessages> {
    return this.put<IChatReadMessages>(`${this.chatsV1Url}/${chatId}`, data);
  }

  getChatsHistoryList(): Observable<IChatRead[]> {
    return this.get<IChatRead[]>(`${this.chatsV1Url}/my`);
  }

  postMessage(chatId: number, chatWithNewMessage: IChatUpdateWithNewMessage): Observable<IChatReadMessages> {
    return this.post<IChatReadMessages>(`${this.chatsV1Url}/user-message/${chatId}`, chatWithNewMessage);
  }
}
