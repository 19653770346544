export enum ChatModelEnum {
  gpt35 = 'gpt-3.5-turbo',
  gpt4 = 'gpt-4',
  gpt4vision = 'gpt-4-vision-preview',
}

export enum GPTChatRolesEnum {
  user = 'user',
  assistant = 'assistant',
  system = 'system',
}

export enum SidebarChatItemActionsEnum {
  delete = 'delete',
  rename = 'rename',
}

export enum UserRolesEnum {
  HEAD = 'HEAD',
  USER = 'USER',
}

export enum KeycloakUserRolesEnum {
  HEAD = 'head',
  USER = 'user',
}
