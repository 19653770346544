import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localeEN from "@angular/common/locales/en-IE";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NbDialogModule,
  NbLayoutModule,
  NbMenuModule,
  NbThemeModule,
  NbThemeService,
  NbToastrModule
} from "@nebular/theme";
import { environment } from "environments/environment";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { MARKED_OPTIONS, MarkdownModule } from "ngx-markdown";
import { AuthGuard } from "./@core/guards/auth-guard";
import { RoleGuard } from "./@core/guards/role-guard";
import { BackendService, UserService, UtilsService } from "./@core/services";
import { ChatService } from "./@core/services/chat.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ErrorPageComponent } from "./service-pages/error-page/error-page.component";
import { LogoutComponent } from "./service-pages/logout/logout.component";

registerLocaleData(localeEN);

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: '/auth/',
        realm: environment.KEYCLOAK_REALM,
        clientId: environment.KEYCLOAK_CLIENT_ID,
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
    });
}

@NgModule({
  declarations: [AppComponent, ErrorPageComponent, LogoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    NbMenuModule.forRoot(),
    NbThemeModule.forRoot(),
    NbLayoutModule,
    KeycloakAngularModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: true,
          pedantic: false,
        },
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    { provide: NbThemeService, useClass: NbThemeService },
    { provide: LOCALE_ID, useValue: 'en-IE' },
    { provide: BackendService, useClass: BackendService },
    { provide: ChatService, useClass: ChatService },
    { provide: UtilsService, useClass: UtilsService },
    { provide: UserService, useClass: UserService },
    AuthGuard,
    RoleGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
