import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/@core/services';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent {
  constructor(private userService: UserService, private keycloakService: KeycloakService, private router: Router) {
    this.userService.logOut();
    void this.router.navigate(['chats']);
    setTimeout(() => {
      void this.keycloakService.logout();
    }, 150);
  }
}
